<template>
  <div>
    <v-card>
      <v-card-title class="justify-center mb-4">
        <span>
          {{ titleModal }}
        </span>

        <v-spacer />

        <v-icon
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-data-table
        :items="dataToArray"
        :headers="headers"
        loading-text="Carregando dados..."
        class="text-no-wrap"
        disable-sort
      >
        <template #[`item.percentage`]="{ item }">
          {{ Number(item.percentage).toFixed(2) }}%
        </template>

        <template #[`item.growth_bonus`]="{ item }">
          {{ item.growth_bonus
            ? Number(item.growth_bonus).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00' }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    dataInfos: {
      type: Object,
      required: true,
    },
    titleModal: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        mdiClose,
      },
      dataToArray: [],
      headers: [
        { text: 'FILIAL', value: 'region', align: '' },
        { text: 'LOJA', value: 'fantasy_name', align: '' },
        { text: 'FUNCIONÁRIO', value: 'name', align: 'center' },
        { text: 'CPF', value: 'cpf', align: 'center' },
        { text: 'NÍVEL', value: 'level', align: '' },
        { text: 'META ATUAL', value: 'goal', align: 'center' },
        { text: 'REALIZADO', value: 'sales', align: 'center' },
        { text: 'EXCEDENTE', value: 'exceed_standard_goals', align: 'center' },
        { text: 'ADIMPLÊNCIA', value: 'percentage', align: 'center' },
        { text: 'VALOR DO BÔNUS', value: 'growth_bonus', align: 'center' },

      ],
    }
  },

  created() {
    this.dataToArray = [this.dataInfos]
  },
}
</script>

<style scoped>

</style>
