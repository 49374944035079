import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center mb-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.titleModal)+" ")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"items":_vm.dataToArray,"headers":_vm.headers,"loading-text":"Carregando dados...","disable-sort":""},scopedSlots:_vm._u([{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.percentage).toFixed(2))+"% ")]}},{key:"item.growth_bonus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.growth_bonus ? Number(item.growth_bonus).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }