<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article"
  ></v-skeleton-loader>

  <div v-else>
    <v-card
      class="px-6 py-6"
    >
      <div class="d-flex py-4">
        <div class="d-flex flex-column">
          <span class="mb-2 mt-2">Bonificação top 3</span>

          <div
            class="d-flex items-center mb-2 mt-2"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiCalendarAccountOutline }}
            </v-icon>
            <span>REFERÊNCIA: {{ dataTopThree.biggest_complice.reference }}</span>
          </div>

          <div
            class="d-flex items-center mb-2 mt-2"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiOfficeBuildingMarkerOutline }}
            </v-icon>
            <span>FILIAL: {{ bodyFilter.region ? bodyFilter.region : initialDataFilter.region }}</span>
          </div>
        </div>

        <v-spacer />

        <v-btn
          :color="bodyFilter.region ? 'success' : 'gray'"
          class="me-3"
          outlined
          @click="openModalFilter"
        >
          <v-icon
            size="20"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span>
            FILTRO
          </span>
        </v-btn>

        <v-btn
          class="mr-2"
          color="warning"
          outlined
          @click="exportCsv"
        >
          <span class="ml-2">Exportar</span>
          <v-img
            class="ml-2"
            :src="require('@/assets/download-icon.svg')"
          />
        </v-btn>
      </div>

      <div class="d-flex justify-content-between items-center container-cards">
        <div class="card">
          <span>Maior adimplência</span>
          <small>2° Parcela</small>

          <div class="d-flex items-center mt-4">
            <v-avatar
              size="50"
              color="info"
              class="v-avatar-light-bg info--text"
            >
              <v-img
                v-if="dataTopThree.biggest_complice && dataTopThree.biggest_complice.base64"
                :src="dataTopThree.biggest_complice.base64"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
                color="success"
              >
                {{ avatarText(dataTopThree.biggest_complice.name ? dataTopThree.biggest_complice.name : '') }}
              </span>
            </v-avatar>

            <div class="d-flex flex-column items-center justify-center ms-3">
              <span class="">{{ dataTopThree.biggest_complice.name ? dataTopThree.biggest_complice.name : '' }}</span>

              <div
                :class="getCompanyColor(dataTopThree.biggest_complice.fantasy_name ? dataTopThree.biggest_complice.fantasy_name : '')"
                class="badge"
              >
                <span class="">{{ dataTopThree.biggest_complice.fantasy_name ? dataTopThree.biggest_complice.fantasy_name : '' }}</span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-4 actions">
            <div>
              <v-avatar
                color="info"
                size="50"
                rounded
                class="v-avatar-light-bg info--text"
              >
                <v-icon
                  dark
                  color="info"
                  size="25"
                >
                  {{ icons.mdiBookmarkCheckOutline }}
                </v-icon>
              </v-avatar>

              <span class="ml-6 text-2xl">{{ Number(dataTopThree.biggest_complice.percentage ? dataTopThree.biggest_complice.percentage : 0).toFixed(2) }}%</span>
            </div>

            <div
              class="details"
              @click="openModalDetailsWithData(dataTopThree.biggest_complice, 'Maior adimplência')"
            >
              <span>Detalhes</span>

              <v-img
                class="arrow"
                :width="12"
                src="@/assets/arrow-down-icon.svg"
              ></v-img>
            </div>
          </div>
        </div>

        <div class="card">
          <span class="mb-5">Maior volume de vendas</span>

          <div class="d-flex items-center mt-4">
            <v-avatar
              color="purple"
              class="v-avatar-light-bg purple--text"
              size="50"
            >
              <v-img
                v-if="dataTopThree.max_sales && dataTopThree.max_sales.base64"
                :src="dataTopThree.max_sales.base64"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
                color="success"
              >
                {{ avatarText(dataTopThree.max_sales ? dataTopThree.max_sales.name : '') }}
              </span>
            </v-avatar>

            <div class="d-flex flex-column items-center justify-center ms-3">
              <span class="">{{ dataTopThree.max_sales ? dataTopThree.max_sales.name : '' }}</span>

              <div
                :class="getCompanyColor(dataTopThree.max_sales ? dataTopThree.max_sales.fantasy_name : '')"
                class="badge"
              >
                <span class="">{{ dataTopThree.max_sales ? dataTopThree.max_sales.fantasy_name : '' }}</span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-4 actions">
            <div>
              <v-avatar
                color="purple"
                size="50"
                rounded
                class="v-avatar-light-bg purple--text"
              >
                <v-icon
                  dark
                  color="purple"
                  size="25"
                >
                  {{ icons.mdiCardsOutline }}
                </v-icon>
              </v-avatar>

              <span class="ml-6 text-2xl">{{ dataTopThree.max_sales ? dataTopThree.max_sales.sales : '' }}</span>
            </div>

            <div
              class="details"
              @click="openModalDetailsWithData(dataTopThree.max_sales, 'Maior volume de vendas')"
            >
              <span>Detalhes</span>

              <v-img
                class="arrow"
                :width="12"
                src="@/assets/arrow-down-icon.svg"
              ></v-img>
            </div>
          </div>
        </div>

        <div class="card">
          <span class="mb-5">Maior excedente</span>

          <div class="d-flex items-center mt-4">
            <v-avatar
              color="success"
              class="v-avatar-light-bg success--text"
              size="50"
            >
              <v-img
                v-if="dataTopThree.max_exceed && dataTopThree.max_exceed.base64"
                :src="dataTopThree.max_exceed.base64"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
                color="success"
              >
                {{ avatarText(dataTopThree.max_exceed ? dataTopThree.max_exceed.name : '') }}
              </span>
            </v-avatar>

            <div class="d-flex flex-column items-center justify-center ms-3">
              <span class="">{{ dataTopThree.max_exceed ? dataTopThree.max_exceed.name : '' }}</span>

              <div
                :class="getCompanyColor(dataTopThree.max_exceed ? dataTopThree.max_exceed.fantasy_name : '')"
                class="badge"
              >
                <span class="">{{ dataTopThree.max_exceed ? dataTopThree.max_exceed.fantasy_name : '' }}</span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-4 actions">
            <div class="d-flex">
              <v-avatar
                color="success"
                size="50"
                rounded
                class="v-avatar-light-bg success--text"
              >
                <v-icon
                  dark
                  color="success"
                  size="25"
                >
                  {{ icons.mdiUploadOutline }}
                </v-icon>
              </v-avatar>

              <div class="bigger-surplus">
                <span class="ml-6 text-2xl">{{ dataTopThree.max_exceed ? dataTopThree.max_exceed.sales : '' }}</span>
                <small
                  style="font-size: 13px"
                  class="ml-6"
                >+{{ dataTopThree.max_exceed ? dataTopThree.max_exceed.exceed_standard_goals : '' }} cotas acima da meta</small>
              </div>
            </div>

            <div
              class="details"
              @click="openModalDetailsWithData(dataTopThree.max_exceed, 'Maior excedente')"
            >
              <span>Detalhes</span>

              <v-img
                class="arrow"
                :width="12"
                src="@/assets/arrow-down-icon.svg"
              ></v-img>
            </div>
          </div>
        </div>
      </div>

      <v-dialog
        v-model="openModal"
        max-width="500px"
        persistent
      >
        <ModalTopThree
          @close="openModal = false"
          @filteredData="$event => setTableData($event)"
          @filteredBody="$event => setBodyFilter($event)"
        ></ModalTopThree>
      </v-dialog>

      <v-dialog
        v-model="openModalDetails"
        max-width="1500px"
        persistent
      >
        <ModalDetailsTopThree
          :key="typeDetail"
          :data-infos="dataDetails"
          :title-modal="typeDetail"
          @close="openModalDetails = false"
        ></ModalDetailsTopThree>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiBookmarkCheckOutline,
  mdiCalendarAccountOutline,
  mdiCardsOutline,
  mdiFilterVariant, mdiOfficeBuildingMarkerOutline, mdiUploadOutline,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalDetailsTopThree from './ModalDetailsTopThree.vue'
import ModalTopThree from './ModalTopThree.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    ModalTopThree,
    ModalDetailsTopThree,
  },

  mixins: [formatters],

  data() {
    return {
      typeDetail: '',
      dataDetails: {},
      openModalDetails: false,
      skeleton: false,
      initialDataFilter: {},
      openModal: false,
      icons: {
        mdiCalendarAccountOutline,
        mdiOfficeBuildingMarkerOutline,
        mdiFilterVariant,
        mdiBookmarkCheckOutline,
        mdiCardsOutline,
        mdiUploadOutline,
      },
      dataTopThree: {},
      bodyFilter: {},
    }
  },

  computed: {

  },

  created() {
    this.handleGetDataInitial()
  },

  methods: {
    getCompanyColor(companyName) {
      const colorMap = {
        'MOTOCA IMPERATRIZ': 'badge-card-itz',
        'MOTOCA AMARANTE': 'badge-card-amarante',
        'MOTOCA CIDELÂNDIA': 'badge-card-cidelandia',
        'MOTOCA PORTO FRANCO': 'badge-card-porto-franco',
        'MOTOCA SENADOR': 'badge-card-senador',
        'MOTOCA AÇAILÂNDIA': 'badge-card-acailandia',
        'MOTOCA ITINGA': 'badge-card-itinga',
        'MOTOCA BOM JESUS': 'badge-card-bom-jesus',
        'MOTOCA GRAJAÚ': 'badge-card-grajau',
        'MOTOCA FORMOSA': 'badge-card-formosa',
        'MOTOCA SÍTIO NOVO': 'badge-card-sitio-novo',
        'MOTOCA ARAME': 'badge-card-arame',
        'FUJI MOTORS': 'badge-card-fuji-motors',
      }

      return colorMap[companyName] || ''
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      this.loaderExport = true
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'FILIAL', key: 'region' },
        { header: 'LOJA', key: 'fantasy_name' },
        { header: 'FUNCIONÁRIO', key: 'name' },
        { header: 'CPF', key: 'cpf' },
        { header: 'NÍVEL', key: 'level' },
        { header: 'META ATUAL', key: 'goal' },
        { header: 'REALIZADO', key: 'sales' },
        { header: 'EXCEDENTE', key: 'exceed_standard_goals' },
        { header: 'ADIMPLÊNCIA', key: 'percentage' },
        { header: 'VALOR DO BÔNUS', key: 'growth_bonus' },
      ]

      const dataToExport = [this.dataTopThree.max_exceed, this.dataTopThree.max_sales, this.dataTopThree.biggest_complice]

      dataToExport.map(itemTable => {
        worksheet.addRow([
          itemTable.region,
          itemTable.fantasy_name,
          itemTable.name,
          itemTable.cpf,
          itemTable.level,
          itemTable.goal,
          itemTable.sales,
          itemTable.exceed_standard_goals,
          Number(itemTable.percentage).toFixed(2),
          itemTable.growth_bonus,
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_top_3', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      return blob
    },

    openModalDetailsWithData(data, type) {
      this.openModalDetails = true

      this.dataDetails = data

      this.typeDetail = type
    },

    openModalFilter() {
      this.openModal = true
    },
    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')
      const first = nameArray[0]
      const last = nameArray[nameArray.length - 1]

      const newName = [first, last]

      return newName.map(word => word.charAt(0).toUpperCase()).join('')
    },

    async handleGetDataInitial() {
      this.skeleton = true

      const regionUserLogged = localStorageSlim.get('userProfile', { decrypt: true })

      const now = new Date()

      let year = now.getFullYear()
      let month = now.getMonth()

      if (month === 0) {
        month = 12
        year -= 1
      }

      month = String(month).padStart(2, '0')

      const dateFormatted = `${year}-${month}`

      this.initialDataFilter = {
        region: regionUserLogged.company.city,
        reference: dateFormatted,
      }

      const body = {
        region: regionUserLogged.company.city,
        reference: dateFormatted,
      }

      try {
        const response = await axiosIns.post('/api/v1/integration/top_bonus/filter', body)

        this.dataTopThree = response.data.top3
      } catch (error) {
        console.log('deu zica', error)
      } finally {
        this.skeleton = false
      }
    },

    setTableData(data) {
      // this.listOfFilteredItems = data
      this.dataTopThree = data
    },

    setBodyFilter(data) {
      this.bodyFilter = data
    },
  },
}
</script>
<style scoped>
  .actions{
    justify-content: space-between;
    align-items: flex-end;
  }

  .card{
    background: #3b355a;
    width: 33%;
    border-radius: 10px;
    padding: 15px;

    display: flex;
    flex-direction: column;
  }

  .badge {
    display: flex;
    justify-content: center;
    padding: 4px 15px;
    font-size: 10px;
    border-radius: 50px;
    margin-top: 5px;
    width: 150px;
    color: #FFFFFF;
  }

  .badge-card-itz {
  background: #4f4251;
  color: #FFB400;
  }

  .badge-card-amarante {
    background: #ADD8E61A;
    color: #ADD8E6;
  }

  .badge-card-cidelandia {
    background: #FF885C1A;
    color: #FF885C;
  }

  .badge-card-porto-franco {
    background: #FFCEFF1A;
    color: #FFCEFF;
  }

  .badge-card-senador {
    background: #74E5A71A;
    color: #74E5A7;
  }

  .badge-card-acailandia {
    background: #D97CEB1A;
    color: #D97CEB;
  }

  .badge-card-itinga {
    background: #C2FFE51A;
    color: #C2FFE5;
  }

  .badge-card-bom-jesus {
    background: #B7AAFF1A;
    color: #B7AAFF;
  }

  .badge-card-grajau {
    background: #75A5331A;
    color: #75A533;
  }

  .badge-card-formosa {
    background: #F5F5DC1A;
    color: #F5F0DC;
  }

  .badge-card-sitio-novo {
    background: #40E0D01A;
    color: #40E0D0;
  }

  .badge-card-arame {
    background: #A2FF711A;
    color: #A2FF71;
  }

  .badge-card-fuji-motors {
    background: #6193FF1A;
    color: #6193FF;
  }

  .badge-card-default {
    background: #6193FF1A;
    color: #6193FF;
  }

  .container-cards{
    gap: 30px;
  }

  .details{
    background: #E7E3FC1A;
    padding: 3px 20px;
    font-size: 12px;
    border-radius: 30px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details:hover{
    text-decoration: underline;
  }

  .arrow{
    rotate: -90deg;
    margin-left: 8px;
  }

  .bigger-surplus{
    display: flex;
    flex-direction: column;
  }

  .bigger-surplus small{
    font-size: 10px;
    color: #39CE2F;
  }

</style>
